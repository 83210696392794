/* eslint-disable */
import { useEffect, useState } from "react"
import "./PopularVehicles.css"
import axios from "axios"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { usersActions } from "../../../store/user";
import { useDispatch, useSelector } from 'react-redux'

function formatNumberWithCommas(number) {
    return number.toLocaleString('en-US');
}

function convertMilliseconds(ms) {
  // Constants for time conversions
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const millisecondsInAnHour = 60 * 60 * 1000;
  const millisecondsInAMinute = 60 * 1000;

  // Calculate days
  const days = Math.floor(ms / millisecondsInADay);
  ms %= millisecondsInADay;

  // Calculate hours
  const hours = Math.floor(ms / millisecondsInAnHour);
  ms %= millisecondsInAnHour;

  // Calculate minutes
  const minutes = Math.floor(ms / millisecondsInAMinute);

  return { days, hours, minutes };
}

const PopularVehicles = () => {
    const [data, setData] = useState([]);
    const [carouselCount, setCarouselCount] = useState(0);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);

    useEffect(() => {
        axios.post(`/api/auction/copart/recomended-lots`, {token: token == null ? "unset" : token})
            .then((res) => {
                setData([...res.data.data]);
                const t = res.data.token;
                if(token == null) dispatch(usersActions.setToken(t));
            })
            .catch(() => {

            })
    }, [])

    const handlers = {
        nextCarouselSlide (){
            setCarouselCount((prev) => {
                const nextSlide = prev < data.length - 4 ? prev + 1 : 0;
                return nextSlide
            })
        },
        prevCarouselSlide (){
            setCarouselCount((prev) => {
                const prevSlide = prev != 0 ? prev - 1 : data.length - 4;
                return prevSlide
            })
        },
        addToFavorite(lot, country){
            axios.put(`/api/favorites`, {lot, token, country})
                .then((res) => {
                    // console.log(res)
                })
                .catch((err) => {
                    // console.log(err)
                })
        }
    }

    return(
        <div className="popular-vehicle-container">
            <div className="vehicle-type-selector-head">
                <h3 className="vehicle-type-selector-title">პოპულარული მანქანები</h3>
                <Link className={"view-all-button"} to="/catalogue">ყველას ნახვა</Link>
            </div>
            <div className="main-page-recomended-lots-container">
                <div className="main-page-recomended-lots-carousel" style={{marginLeft: `${carouselCount * -356}px`}}>
                    {
                        data.length > 0 && (
                            data.map((car) => {
                                return (
                                    <div className="cars-items-card-box-cont"key={car.lotNumber}>
                                        <button className="add-to-favorite-populars" onClick={() => handlers.addToFavorite(car.lotNumber, car.currencyCode == "CAD" ? "CAN" : "USA")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                <g clipPath="url(#clip0_313_636)">
                                                    <path d="M9.39062 12C9.15156 12 8.91671 11.9312 8.71128 11.8009L6.11794 10.1543C6.04701 10.1091 5.95296 10.1096 5.88256 10.1543L3.28869 11.8009C2.8048 12.1082 2.13755 12.0368 1.72722 11.6454C1.47556 11.4047 1.33685 11.079 1.33685 10.728V1.2704C1.33738 0.570053 1.90743 0 2.60778 0H9.39272C10.0931 0 10.6631 0.570053 10.6631 1.2704V10.728C10.6631 11.4294 10.0925 12 9.39062 12ZM6.00025 9.06935C6.24193 9.06935 6.47783 9.13765 6.68169 9.26743L9.27503 10.9135C9.31233 10.9371 9.35069 10.9487 9.39114 10.9487C9.48046 10.9487 9.61286 10.8788 9.61286 10.728V1.2704C9.61233 1.14956 9.51356 1.05079 9.39272 1.05079H2.60778C2.48642 1.05079 2.38817 1.14956 2.38817 1.2704V10.728C2.38817 10.7911 2.41023 10.8436 2.45384 10.8851C2.52582 10.9539 2.63563 10.9708 2.72599 10.9135L5.31934 9.2669C5.52267 9.13765 5.75857 9.06935 6.00025 9.06935Z" fill="black"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_313_636">
                                                        <rect width="12" height="12" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </button>
                                        <Link className="cars-items-card-box" key={car.lotNumber} to={`/vehicle/${car.lotNumber}/${car.currencyCode == "CAD" ? "CAN" : "USA"}`} >
                                            <div className="vehicle-card-thumbnail" style={{backgroundImage: `url('${car.imageUrl}')`}}>
                                            </div>
                                            <div className="vehicle-card-details-box">
                                            <h6>{car.mark} {car.model}</h6>
                                            <p className="vehicle-card-car-name">{car.lotDesc}</p>
                                            <p className="vehicle-card-lot">LOT#: {car.lotNumber}</p>
                                            <div className="vehicle-card-amount-content">
                                                <div className="vehicle-card-current-bid">
                                                <p className="vehicle-card-current-bid-title">მიმდინარე ფასი </p>
                                                <p className="vehicle-card-current-bid-amount">{formatNumberWithCommas(car.currentHighBid)} $</p>
                                                </div>
                                                <div className="vehicle-card-buy-now-price">
                                                <p className="vehicle-card-buy-now-title">ახლავე ყიდვა </p>
                                                <p className="vehicle-card-buy-now-amount">{formatNumberWithCommas(car.currentHighBid)} $</p>
                                                </div>
                                            </div>
                                            <hr className="vehicle-card-hr"/>
                                            <div className="vehicle-card-auction-info" style={{justifyContent: "flex-end"}}>
                                                <div className="vehicle-card-viewers">
                                                    <img src="/static/icons/eye.svg" alt="eye"/>
                                                    <span>1 ნახულობს</span>
                                                </div>
                                            </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        )
                    }
                </div>
            </div>
            <div className="main-page-recomended-lot-slider-buttons">
                <button onClick={handlers.prevCarouselSlide} className="main-page-recomended-lot-slider-button" style={{marginRight: "24px"}}><img src="/static/icons/next-slider-black.svg" alt="arrow" style={{transform: "rotate(180deg)"}} /></button>
                <button onClick={handlers.nextCarouselSlide} className="main-page-recomended-lot-slider-button"><img src="/static/icons/next-slider-black.svg" alt="arrow" /></button>
            </div>
        </div>
    )
}

export default PopularVehicles