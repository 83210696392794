/* eslint-disable */
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import "../stylesheet/VehicleDetails.css"
import { useEffect, useState } from "react"
import axios from "axios";
import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux'

const VehicleDetails = ({currentLot}) => {
    const [data, setData] = useState({});
    const lot = window.location.pathname.slice(9, 17);
    const [images, setImages] = useState({count: 0, list: []});
    const [activeImage, setActiveImage] = useState(0);
    const [auctionFee, setAuctionFee] = useState({amount: "0", copartFee: "0", virtualBidFee: "0", envirolmentFee: "10", gateFee: "79", sum: "89"});
    const [fee, setFee] = useState({});
    const token = useSelector((state) => state.user.token);
    const country = window.location.pathname.slice(18);
    const [amount, setAmount] = useState("0");
    const [inProgress, setInProgress] = useState(false);
    

    function timeLeftInDDHHMM(milliseconds) {
        const timeLeft = milliseconds - +new Date();
        const days = Math.floor(timeLeft / 86400000);
        let totalSeconds = timeLeft % 86400000;
        totalSeconds %= 86400000;
        const hours = Math.floor(totalSeconds / 3600000);
        totalSeconds %= 3600000;
        const minutes = Math.floor(totalSeconds / 60000);
        return `${days.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: for smooth scrolling
        });
    }, [])

    useEffect(() => {
        axios.get(`https://carspace.crg.ge/api/fees`)
            .then((res) => {
                setFee({...res.data.data});
            })
            .catch(() => {
                
            })
        axios.get(`/api/auction/copart/car-details/${lot}?token=${token}&country=${country}`)
            .then((res) => {
                const imagesData = res.data.data.imagesList;
                setImages({count: imagesData.totalElements, list: [...imagesData.content]});
                setData({...res.data.data.lotDetails});
                setAmount(res.data.data.lotDetails.dynamicLotDetails.currentBid)
            })
            .catch(() => {
                
            })
    }, []);

    const handlers = {
        nextSlide(){
            setActiveImage((prev) => {
                const nextSlide = prev < images.count - 1 ? prev + 1 : 0;
                return nextSlide
            })
        },
        prevSlide(){
            setActiveImage((prev) => {
                const prevSlide = prev == 0 ? images.count - 1 : prev - 1;
                return prevSlide
            })
        },
        calculateAuctionFee(){
            if(auctionFee.amount < 15000){
                const selectFeeObject = fee.copart.high.filter((f) => auctionFee.amount >= f.from && auctionFee.amount < f.to);
                const selectVirtualFee = fee.virtual.livebid.filter((f) => auctionFee.amount >= f.from && auctionFee.amount < f.to);
                setAuctionFee((prev) => ({...prev, virtualBidFee: selectVirtualFee[0].price, copartFee: selectFeeObject[0].price, sum:  selectFeeObject[0].price + selectVirtualFee[0].price + 79 + 10 + 30 + 20 + parseFloat(auctionFee.amount)}));
            }else{
                const copartFee = (auctionFee.amount / 100) * fee.copart.high[fee.copart.high.length - 1].percent;
                const selectVirtualFee = fee.virtual.livebid.filter((f) => auctionFee.amount >= f.from && auctionFee.amount < f.to);
                setAuctionFee((prev) => ({...prev, virtualBidFee: selectVirtualFee[0].price, copartFee: copartFee, sum: copartFee + selectVirtualFee[0].price + 79 + 10 + 30 + 20 + parseFloat(auctionFee.amount)}));
            }
        },
        increaseAmount(){
            setAmount((prev) => prev - 50)
        },
        riseAmount(){
            setAmount((prev) => parseInt(prev) + 50)
        },
        placePreBid(){
            setInProgress(true)
            axios.post('/api/copart/pre-bid/bid-now', {amount: amount, lot: lot})
                .then((res) => {
                    setInProgress(false)
                })
                .catch(() => {
                    setInProgress(false)
                })
        },
        addToFavorites(){
            axios.put('/api/favorites', {lot: lot, token: token, country: country})
                .then(() => {

                })
                .catch(() => {

                })
        }
    }

    return (
        <div className="vehicle-details-page-container">
            {inProgress && <div className="loading-to-bid">
                <p style={{color: "white", fontSize: "32px"}}>Bidding is in process. Please wait...</p>
            </div>}
            <div className="vehicle-details-page-content">
                <div className="pager-history">
                    <Link to="/">მთავარი</Link>
                    <span> / </span>
                    <Link to="/catalogue">კატალოგი</Link>
                    <span> / </span>
                    <span>{data.mkn} {data.lmg}</span>
                </div>
                {
                    typeof data.mkn == "undefined" ? <Skeleton variant="text" sx={{ fontSize: '36px' }} style={{marginTop: "36px", marginBottom: "8px", height: "43px", width: "250px"}} /> : <h1>{data.mkn} {data.lm}</h1>
                }
                <div className="vehicle-details-columns-container">
                    <div className="vehicle-details-images-carousel-slider vehicle-details-column">
                        <span className="vehicle-details-lot-number">LOT# {lot}</span>
                        <div className="vehicle-details-images-carousel-slider-box" style={{backgroundImage: `url(${typeof images.list[activeImage] != "undefined" && images.list[activeImage].highResUrl})`}}>
                            {typeof data.mkn == "undefined" && <Skeleton variant="rounded" width={"100%"} height={"100%"} style={{position: "absolute"}} />}
                            <button onClick={handlers.prevSlide} className="slide-switcher-button"><img src="/static/icons/next.svg" alt="next" style={{transform: `rotate(180deg)`}}/></button>
                            <button onClick={handlers.nextSlide} className="slide-switcher-button"><img src="/static/icons/next.svg" alt="next"/></button>
                        </div>
                        <div className="vehicle-details-carousel-items-list">
                            <div className="vehicle-details-carousel-items-list-box" style={{marginLeft: activeImage < 3 ? "0" : `${(activeImage - 2) * -222}px` }}>
                                {
                                    images.count > 0 ? images.list.map((img, i) => {
                                        return(
                                            <div onClick={() => setActiveImage(i)} className={`vehicle-details-carousel-list-item ${activeImage == i ? "list-active-color-border" : ""}`} style={{backgroundImage: `url('${img.fullUrl}')`}}></div>
                                        )
                                    }) : (
                                        <>
                                            <Skeleton variant="rounded" style={{width: "204px", height: "164px", marginRight: "18px"}} />
                                            <Skeleton variant="rounded" style={{width: "204px", height: "164px", marginRight: "18px"}} />
                                            <Skeleton variant="rounded" style={{width: "204px", height: "164px", marginRight: "18px"}} />
                                            <Skeleton variant="rounded" style={{width: "204px", height: "164px", marginRight: "18px"}} />
                                            <Skeleton variant="rounded" style={{width: "204px", height: "164px", marginRight: "18px"}} />
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <div className="vehicle-details-car-condition-container">
                            <h3 className="vehicle-details-condition-title">მდგომარეობა</h3>
                            <div className="vehicle-details-condition-boxes">
                                <div className="vehicle-details-condition-box">
                                    <img src="/static/icons/primary-damage.svg" alt="primary-damage"/>
                                    <span>დაზიანება</span>
                                    {typeof data.dd != "undefined" ? <strong>{data.dd}</strong> :
                                    <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "70px"}} />}
                                </div>
                                <div className="vehicle-details-condition-box">
                                    <img src="/static/icons/engine.svg" alt="primary-damage"/>
                                    <span>მანქანის მდგომარეობა</span>
                                    {typeof data.dd != "undefined" ? <strong>{data.lcd}</strong> :
                                    <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "40px"}} />}
                                </div>
                                <div className="vehicle-details-condition-box">
                                    <img src="/static/icons/secondary-damage.svg" alt="primary-damage"/>
                                    <span>მეორადი დაზიანება</span>
                                    {typeof data.dd != "undefined" ? <strong>{data.dd}</strong> :
                                    <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "80px"}} />}
                                </div>
                                <div className="vehicle-details-condition-box">
                                    <img src="/static/icons/drive-wheels.svg" alt="primary-damage"/>
                                    <span>წამყვანი თვლები</span>
                                    {typeof data.dd != "undefined" ? <strong>{data.drv}</strong> :
                                    <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "80px"}} />}
                                </div>
                                <div className="vehicle-details-condition-box">
                                    <img src="/static/icons/calendar.svg" alt="primary-damage"/>
                                    <span>წელი</span>
                                    {typeof data.dd != "undefined" ? <strong>{data.lcy}</strong> :
                                    <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "80px"}} />}
                                </div>
                            </div>
                        </div>
                        <div className="vehicle-details-hr"></div>
                        <div className="vehicle-details-description-block">
                            <p>
                                Lorem ipsum dolor sit amet consectetur. Lacinia ornare aliquet lectus quam consectetur ac urna ut. Pulvinar consectetur non Lacinia 
                                ornare aliquet lectus quam consectetur ac urna ut. Pulvinar consectetur non sed iaculis tincidunt dui. Nunc fringilla ornare varius purus 
                                massa magna sapien eu. Vitae neque varius adipiscing mattis platea.
                            </p>
                        </div>
                        <div className="auction-calculator-container">
                            <div className="auction-calculator-container-banner" style={{backgroundImage: "url('/static/auction-calculator-banner.png')"}}></div>
                            <div className="auction-calculator-form">
                                <div>
                                    <h3 className="auction-fee-calculator-title">აუქციონის დანამატის კალკულატორი</h3>
                                    <div className="auction-winning-amount-block">
                                        <input className="auction-winning-amount-field" onChange={(e) => setAuctionFee((prev) => ({...prev, amount: e.target.value}))} onKeyDown={(e) => {
                                            if(e.keyCode == 13) handlers.calculateAuctionFee();
                                        }} placeholder="საორიენტაციო თანხა"/>
                                        <button className="auction-fee-calculate-button" onClick={handlers.calculateAuctionFee}><span>გამოთვლა</span><img src="/static/icons/calculator.svg" alt="calculator"/></button>
                                    </div>
                                    <div className="auction-fee-form-item">
                                        <div className="auction-fee-form-item-image" style={{background: "var(--Background-Primary, rgba(90, 126, 255, 0.10))"}}>
                                            <img src="/static/icons/wallet.svg" alt="item image"/>
                                        </div>
                                        <div className="auction-fee-form-item-detail">
                                            <p>თქვენი თანხა</p>
                                            <span>{auctionFee.amount} $</span>
                                        </div>
                                    </div>
                                    <div className="auction-fee-form-item">
                                        <div className="auction-fee-form-item-image" style={{background: "var(--Background-Primary, rgba(90, 126, 255, 0.10))"}}>
                                            <img src="/static/icons/copart.png" alt="item image"/>
                                        </div>
                                        <div className="auction-fee-form-item-detail">
                                            <p>Copart მოსაკრებელი</p>
                                            <span>{auctionFee.copartFee} $</span>
                                        </div>
                                    </div>
                                    <div className="auction-fee-form-item">
                                        <div className="auction-fee-form-item-image" style={{background: "var(--Background-Primary, rgba(90, 126, 255, 0.10))"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M9.64844 0.625V4.10156H10C10.582 4.10156 10.8984 4.55859 10.9297 5.02344C10.9453 5.25781 10.8828 5.47266 10.7461 5.625C10.6094 5.78125 10.3906 5.89844 10 5.89844C9.80469 5.89844 9.56641 5.78906 9.39062 5.60938C9.21094 5.43359 9.10156 5.19531 9.10156 5H8.39844C8.39844 5.42969 8.60156 5.81641 8.89062 6.10938C9.18359 6.39844 9.57031 6.60156 10 6.60156C10.5469 6.60156 10.9922 6.40625 11.2695 6.09375C11.5508 5.77734 11.6602 5.36719 11.6328 4.97656C11.5859 4.29297 11.1094 3.60234 10.3516 3.43672V0.625H9.64844ZM7.77734 6.11328L2.39609 9.64844H3.67344L8.16016 6.69922L7.77734 6.11328ZM12.2227 6.11328L11.8398 6.69922L16.3281 9.64844H17.6016L12.2227 6.11328ZM1.60156 10.3516V19.0234H18.3984V10.3516H1.60156ZM3.08594 11.25H3.78906V18.125H3.08594V11.25ZM4.96094 11.25H5.66406V18.125H4.96094V11.25ZM6.83594 11.25H7.53906V18.125H6.83594V11.25ZM8.71094 11.25H9.41406V18.125H8.71094V11.25ZM10.5859 11.25H11.2891V18.125H10.5859V11.25ZM12.4609 11.25H13.1641V18.125H12.4609V11.25ZM14.3359 11.25H15.0391V18.125H14.3359V11.25ZM16.2109 11.25H16.9141V18.125H16.2109V11.25Z" fill="#FFB900"/>
                                            </svg>
                                        </div>
                                        <div className="auction-fee-form-item-detail">
                                            <p>მიტანის მოსაკრებელი</p>
                                            <span>{auctionFee.gateFee} $</span>
                                        </div>
                                    </div>
                                    <div className="auction-fee-form-item">
                                        <div className="auction-fee-form-item-image" style={{background: "var(--Background-Primary, rgba(90, 126, 255, 0.10))"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M4.16667 17.5C3.70833 17.5 3.31611 17.3369 2.99 17.0108C2.66389 16.6847 2.50056 16.2922 2.5 15.8333V4.16667C2.5 3.70833 2.66333 3.31611 2.99 2.99C3.31667 2.66389 3.70889 2.50056 4.16667 2.5H15.8333C16.2917 2.5 16.6842 2.66333 17.0108 2.99C17.3375 3.31667 17.5006 3.70889 17.5 4.16667V15.8333C17.5 16.2917 17.3369 16.6842 17.0108 17.0108C16.6847 17.3375 16.2922 17.5006 15.8333 17.5H4.16667ZM4.16667 14.125V15.8333H15.8333V8.54167L11.4583 13.5C11.3056 13.6806 11.1078 13.7744 10.865 13.7817C10.6222 13.7889 10.4172 13.7089 10.25 13.5417L7.5 10.7917L4.16667 14.125ZM4.16667 11.75L6.91667 9C7.08333 8.83333 7.27778 8.75 7.5 8.75C7.72222 8.75 7.91667 8.83333 8.08333 9L10.7917 11.7083L15.8333 6.04167V4.16667H4.16667V11.75ZM4.16667 8.54167V6.04167V11.7083V8.41667V14.125V10.7917V14.1667V8.54167ZM4.16667 11.75V4.16667V11.7083V8.41667V11.75ZM4.16667 14.125V10.7917V14.1667V8.54167V15.8333V14.125Z" fill="#FF4539"/>
                                            </svg>
                                        </div>
                                        <div className="auction-fee-form-item-detail">
                                            <p>ვირტუალური ბიდის მოსაკრებელი</p>
                                            <span>{auctionFee.virtualBidFee} $</span>
                                        </div>
                                    </div>
                                    <div className="auction-fee-form-item">
                                        <div className="auction-fee-form-item-image" style={{background: "var(--Background-Primary, rgba(90, 126, 255, 0.10))"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M18.3333 2.84163L18.2333 1.79163L17.2333 2.12496C15.5195 2.72041 13.6894 2.90314 11.8917 2.65829C10.0026 2.36514 8.06911 2.62564 6.32501 3.40829C5.40041 3.802 4.58897 4.42072 3.96457 5.20813C3.34017 5.99554 2.92265 6.92663 2.75001 7.91663C2.52616 9.19553 2.63814 10.5106 3.07501 11.7333C3.35631 11.0984 3.68501 10.4855 4.05834 9.89996C4.90518 8.65399 6.07857 7.66511 7.45001 7.04163C8.77121 6.4384 10.2153 6.153 11.6667 6.20829C10.2852 6.42001 8.96273 6.91663 7.78334 7.66663C7.21468 8.02415 6.69383 8.45259 6.23334 8.94163C5.79316 9.43064 5.40496 9.96406 5.07501 10.5333C4.45556 11.7233 4.01472 12.9981 3.76668 14.3166C3.49934 15.6394 3.35429 16.9839 3.33334 18.3333H5.00001C5.0792 17.1232 5.2435 15.9203 5.49168 14.7333C6.64787 15.3576 7.94447 15.676 9.25834 15.6583C10.4837 15.6575 11.6965 15.4109 12.825 14.9333C19.1667 12.225 18.3333 3.21663 18.3333 2.84163Z" fill="#80CB7B"/>
                                            </svg>
                                        </div>
                                        <div className="auction-fee-form-item-detail">
                                            <p>გარემოსდაცვითი მოსაკრებელი</p>
                                            <span>{auctionFee.envirolmentFee} $</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="auction-fee-form-sum-container">
                                    <div className="fee-form-divider-line"></div>
                                    <div className="auction-fee-form-sum-value">
                                        <span className="auction-fee-form-sum-title">ჯამური ფასი</span>
                                        <span className="auction-fee-form-sum-count-value">{auctionFee.sum} $</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vehicle-details-location">
                            <p>{data.yn}</p>
                        </div>
                    </div>
                    <div className="vehicle-details-information-block vehicle-details-column">
                        <div className="vehicle-details-share-and-save">
                            <div className="vehicle-details-share">
                                <span>გაზიარება</span>
                                <button className="share-and-save-buttons"><img src="/static/icons/share.svg" alt="share"/></button>
                            </div>
                            <div className="vehicle-details-save">
                                <span>შენახვა</span>
                                <button className="share-and-save-buttons" onClick={handlers.addToFavorites}><img src="/static/icons/save.svg" alt="share"/></button>
                            </div>
                        </div>
                        <div className="vehicle-details-bid-section">
                            <div className="vehicle-details-auction-information">
                                <div className="vehicle-details-bid-info">
                                    <p className="vehicle-details-current-bid-title">მიმდინარე ფასი</p>
                                    {typeof data.dynamicLotDetails != "undefined" ? <p className="vehicle-details-current-bid">${data.dynamicLotDetails.currentBid}</p> : <Skeleton variant="text" sx={{fontSize: "25px"}} style={{width: "100px", margin: "auto"}} />}
                                </div>
                                <div className="vehicle-details-auction-start-date">
                                    <p className="vehicle-details-auction-date-title">აუქციონის დაწყების დრო</p>
                                    {typeof data.dynamicLotDetails != "undefined" ? <p className="vehicle-details-auction-date">{timeLeftInDDHHMM(data.ad).slice(0, 2)} დღე {timeLeftInDDHHMM(data.ad).slice(3)} საათი</p> : <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "100px", margin: "auto"}} />}
                                </div>
                                <div className="place-deposit-box">
                                    <div className="prebid-amount-box">
                                        <svg onClick={handlers.increaseAmount} style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M4 8H12M14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8Z" stroke="#666666"/>
                                        </svg>
                                        <input className="prebid-amount-value" value={amount} min={typeof data.dynamicLotDetails != "undefined" ? data.dynamicLotDetails.currentBid : "0"} onChange={(e) => setAmount(e.target.value)} />
                                        <svg onClick={handlers.riseAmount} style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8 1.5C4.416 1.5 1.5 4.416 1.5 8C1.5 11.584 4.416 14.5 8 14.5C11.584 14.5 14.5 11.584 14.5 8C14.5 4.416 11.584 1.5 8 1.5ZM8 2.5C11.0435 2.5 13.5 4.9565 13.5 8C13.5 11.0435 11.0435 13.5 8 13.5C4.9565 13.5 2.5 11.0435 2.5 8C2.5 4.9565 4.9565 2.5 8 2.5ZM7.5 5V7.5H5V8.5H7.5V11H8.5V8.5H11V7.5H8.5V5H7.5Z" fill="#666666"/>
                                        </svg>
                                    </div>
                                    <button onClick={handlers.placePreBid} style={{fontSize: "10px", color: "white"}} className="place-pre-bid-button">განათავსე დეპოზიტი</button>
                                </div>
                                {data.bnp != 0 && <button className="boy-now-button">იყიდე ახლავე</button>}
                            </div>
                        </div>
                        <div className="vehicle-details-car-information-block">
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/vin.svg" alt="VIN" /> <p className="vehicle-details-car-info-item-title">VIN</p></div>
                                {typeof data.fv != "undefined" ? <p className="vehicle-details-car-info-item-value">{data.fv}</p> : <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "150px"}}/>}
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/car.svg" alt="Veh type" /> <p className="vehicle-details-car-info-item-title">მანქანის ტიპი</p></div>
                                {typeof data.fv != "undefined" ? <p className="vehicle-details-car-info-item-value">{data.vehTypDesc}</p> : <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "110px"}}/>}
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/calendar-black.svg" alt="Year" /> <p className="vehicle-details-car-info-item-title">წელი</p></div>
                                {typeof data.fv != "undefined" ? <p className="vehicle-details-car-info-item-value">{data.lcy}</p> : <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "50px"}}/>}
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/fuel.svg" alt="Fuel" /> <p className="vehicle-details-car-info-item-title">საწვავის ტიპი</p></div>
                                {typeof data.fv != "undefined" ? <p className="vehicle-details-car-info-item-value">{data.ft}</p> : <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "70px"}}/>}
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/transmission.svg" alt="Transmission" /> <p className="vehicle-details-car-info-item-title">ტრანსმისია</p></div>
                                {typeof data.fv != "undefined" ? <p className="vehicle-details-car-info-item-value">{data.tmtp}</p> : <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "140px"}}/>}
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/drive-black.svg" alt="Drive" /> <p className="vehicle-details-car-info-item-title">წამყვანი თვლები</p></div>
                                {typeof data.fv != "undefined" ? <p className="vehicle-details-car-info-item-value">{data.drv}</p> : <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "170px"}}/>}
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/vin.svg" alt="VIN" /> <p className="vehicle-details-car-info-item-title">ბატარეა</p></div>
                                {typeof data.fv != "undefined" ? <p className="vehicle-details-car-info-item-value">{data.fv}</p> : <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "90px"}}/>}
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/door.svg" alt="Doors" /> <p className="vehicle-details-car-info-item-title">კარი</p></div>
                                {typeof data.fv != "undefined" ? <p className="vehicle-details-car-info-item-value">4/5</p> : <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "40px"}}/>}
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/color.svg" alt="Color" /> <p className="vehicle-details-car-info-item-title">ფერი</p></div>
                                {typeof data.fv != "undefined" ? <p className="vehicle-details-car-info-item-value">{data.clr}</p> : <Skeleton variant="text" sx={{fontSize: "14px"}} style={{width: "100px"}}/>}
                            </div>
                        </div>
                        <div className="vehicle-details-consultation-block">
                            <div className="vehicle-details-consultation-manager">
                                <div className="vehicle-details-consultation-manager-image" style={{backgroundImage: "url('/static/manager.svg')"}}></div>
                                <p className="vehicle-details-consultation-manager-name">ნიკოლოზ კიკნაძე</p>
                            </div>
                            <p className="vehicle-details-consultation-manager-text">კითხვა გაქთ? მე დაგეხმარებით შეავსეთ ფორმა და უმოკლეს დროში დაგიკავშირდებით.</p>
                            <input className="vehicle-details-consultation-field" placeholder="სახელი" />
                            <input className="vehicle-details-consultation-field" placeholder="გვარი" />
                            <div className="vehicle-details-consultation-phone-field" style={{marginBottom: 0}}>
                                <div className="vehicle-details-consultation-phone-field-language">
                                    <img src="/static/icons/geo-flag.svg" alt="geo" />
                                    <p>+995</p>
                                </div>
                                <input className="vehicle-details-consultation-field phone-field" style={{marginBottom: 0}} />
                            </div>
                            <button className="send-car-request-button">გაგზავნა</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleDetails