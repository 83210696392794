import { useEffect, useState } from "react";
import "./Transactions.css";

const TransactionPage = ({setHeaderTitle, setNoBackground}) => {
    const [filter, setFilter] = useState({
        startDate: "",
        endDate: ""
    })
    useEffect(() => {
        setHeaderTitle("ბალანსის შევსება");
        setNoBackground(false);
    }, [])

    return(
        <div className="personal-details-page-container transaction-history-container">
            <div className="transactions-page-filter-container">
                <div className="filter-date-box">
                    <input type="date" id="date-from" onChange={(e) => setFilter((prev) => ({...prev, startDate: e.target.value}))} />
                    <label htmlFor="date-from" className="date-filter-input">
                        <div>
                            <span>დასაწყისი</span>
                            <span>{filter.startDate}</span>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.375 1.75H9.625V0.875H8.75V1.75H5.25V0.875H4.375V1.75H2.625C2.14375 1.75 1.75 2.14375 1.75 2.625V11.375C1.75 11.8562 2.14375 12.25 2.625 12.25H11.375C11.8562 12.25 12.25 11.8562 12.25 11.375V2.625C12.25 2.14375 11.8562 1.75 11.375 1.75ZM11.375 11.375H2.625V5.25H11.375V11.375ZM11.375 4.375H2.625V2.625H4.375V3.5H5.25V2.625H8.75V3.5H9.625V2.625H11.375V4.375Z" fill="black"/>
                        </svg>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default TransactionPage