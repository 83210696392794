/* eslint-disable */
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import './App.css';
import {Link} from 'react-router-dom';
import Header from './common/Header/Header';
import Catalogue from './pages/Catalogue';
import VehicleDetails from './pages/VehicleDetails';
import { useState } from 'react';
import MainPage from './pages/MainPage';
import AboutUs from './pages/AboutUs';
import TransportationCalculator from './components/MainPage/TransportationCalculator/TransportationCalculator';
import Contact from './pages/Contact';
import NotFound from './pages/notFound';
import Footer from './common/Footer/Footer';
import Loading from './pages/Loading';
import { useSelector } from 'react-redux';
import Dealers from './pages/Dealers';
import CreateBlog from './pages/admin/CreateBlog';
import BlogDetails from './pages/BlogDetails';
import LoggedInUsers from './pages/LoggedInUser/LoggedInUser';

function App() {
    const [selectedLot, setSelectedLot] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const user = useSelector((state) => state.user);

    const handlers = {
        scrollToTop(){
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Optional: for smooth scrolling
            });
        }
    }

    return (
        <div className="App">
            {isLoading ? <Loading isLoading={setIsLoading}/> : <>
                <Header />
                <button className='scroll-to-top' onClick={handlers.scrollToTop}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M11 8.57101L7.05 12.521C6.8614 12.7032 6.60879 12.804 6.3466 12.8017C6.0844 12.7994 5.83359 12.6942 5.64818 12.5088C5.46277 12.3234 5.3576 12.0726 5.35532 11.8104C5.35305 11.5482 5.45384 11.2956 5.636 11.107L11.293 5.45001C11.3857 5.35683 11.4958 5.28288 11.6171 5.23241C11.7385 5.18195 11.8686 5.15598 12 5.15598C12.1314 5.15598 12.2615 5.18195 12.3829 5.23241C12.5042 5.28288 12.6143 5.35683 12.707 5.45001L18.364 11.107C18.5417 11.2965 18.6386 11.5476 18.6344 11.8073C18.6302 12.067 18.5251 12.3148 18.3414 12.4984C18.1577 12.682 17.9098 12.7869 17.6501 12.7909C17.3904 12.795 17.1393 12.6978 16.95 12.52L13 8.57001V18.156C13 18.4212 12.8946 18.6756 12.7071 18.8631C12.5196 19.0507 12.2652 19.156 12 19.156C11.7348 19.156 11.4804 19.0507 11.2929 18.8631C11.1054 18.6756 11 18.4212 11 18.156V8.57101Z" fill="white"/>
                    </svg>
                </button>
                <Switch>
                    <Route exact path='/'><MainPage /></Route>
                    <Route exact path='/catalogue'><Catalogue setSelectedLot={setSelectedLot} /></Route>
                    <Route exact path='/vehicle/:lot/:country'><VehicleDetails currentLot={selectedLot} /></Route>
                    <Route exact path='/about-us'><AboutUs /></Route>
                    <Route exact path='/dealer-page'><Dealers /></Route>
                    <Route exact path='/contact'><div className='contact-page'><Contact /></div></Route>
                    <Route path='/blog/:id'><BlogDetails /></Route>
                    <Route exact path='/calculator'>
                        <div className='transportation-calculator-page'>
                            <div className="about-us-page-map">
                                <Link to="/">მთავარი</Link>
                                <span>/</span>
                                <Link to="/calculator">კალკულატორი</Link>
                            </div>
                            <h3 className='about-us-title contact-page-title'>კალკულატორი </h3>
                            <TransportationCalculator />
                        </div>
                    </Route>
                    <Route path='/admin/blogs'><CreateBlog /></Route>
                    <Route path='/personal-cabinet'><LoggedInUsers data={user} /></Route>
                    <Route path='/*'><NotFound /></Route>

                </Switch>
                <Footer/>
            </>}
        </div>
    );
}

export default App;
