import { useEffect, useState } from "react"
import axios from "axios"
import Favorites from "./Cards/Favorites";

const FavoriteCars = ({setHeaderTitle, setNoBackground}) => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [data, setData] = useState([]);

    useEffect(() => {
        setHeaderTitle("შენახული მანქანები");
        setNoBackground(true)
        axios(`/api/favorites?limit=${limit}&page=${page}&keyword=${keyword}`)
            .then((res) => {
                setData(res.data.data);
            })
            .catch((err) => {

            })
    }, [])
    return(
        <div className="personal-details-page-container saved-cars-page">
            {
                data.map((car) => {
                    return <Favorites key={car.lot} data={car} />
                })
            }
        </div>
    )
}

export default FavoriteCars