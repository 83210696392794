import axios from "axios";
import { useEffect, useState } from "react"

const PersonalDetails = ({setHeaderTitle, setNoBackground}) => {
    const [currentTab, setCurrentTab] = useState(1);
    const [formData, setFormData] = useState({
        name: '', surname: '', email: '', phone: '', birthDate: '', address: ""
    })
    const [focused, setFocused] = useState({
        name: false, surname: false, email: false, phone: false, birthDate: false, address: false
    })

    const [passwordForm, setPasswordForm] = useState({oldPassword: "", newPassword: "", repeatPassword: ""})

    useEffect(() => {
        axios('/api/user')
            .then((res) => {
                const data = res.data.data;
                setFormData({name: data.name, surname: data.surname, email: data.email, birthDate: data.date_of_birth, address: data.address, phone: data.phone});
            })
            .catch(() => {

            })
        setHeaderTitle("პირადი მონაცემები");
        setNoBackground(false);
    }, [])

    return(
        <div className="personal-details-page-container">
            <div className="switcher-tabs-header">
                <span onClick={() => setCurrentTab(1)} className={`switcher-tab-header-item ${currentTab == 1 ? 'active-tab' : ''}`}>ინფორმაცია</span>
                <span onClick={() => setCurrentTab(2)} className={`switcher-tab-header-item ${currentTab == 2 ? 'active-tab' : ''}`}>პაროლის შეცვლა</span>
            </div>
            {currentTab == 1 && <div className="main-page-contact-form persinal-info-form">
                <div className="form-group">
                    <label className={`form-label ${focused.name || (!focused.name && formData.name != "") ? "label-blured" : ""}`}>სახელი</label>
                    <input 
                        value={formData.name}
                        className="form-control" 
                        onBlur={() => setFocused((prev) => ({...prev, name: false}))}
                        onFocus={() => setFocused((prev) => ({...prev, name: true}))}
                        onChange={(e) => setFormData((prev) => ({...prev, name: e.target.value}))}
                    />
                </div>
                <div className="form-group">
                    <label className={`form-label ${focused.email || (!focused.email && formData.email != "") ? "label-blured" : ""}`}>ელ. ფოსტა</label>
                    <input 
                        className="form-control" 
                        value={formData.email}
                        onBlur={() => setFocused((prev) => ({...prev, email: false}))}
                        onFocus={() => setFocused((prev) => ({...prev, email: true}))}
                        onChange={(e) => setFormData((prev) => ({...prev, email: e.target.value}))}
                    />
                </div>
                <div className="form-group">
                    <label className={`form-label ${focused.surname || (!focused.surname && formData.surname != "") ? "label-blured" : ""}`}>გვარი</label>
                    <input 
                        className="form-control" 
                        value={formData.surname}
                        onBlur={() => setFocused((prev) => ({...prev, surname: false}))}
                        onFocus={() => setFocused((prev) => ({...prev, surname: true}))}
                        onChange={(e) => setFormData((prev) => ({...prev, surname: e.target.value}))}
                    />
                </div>
                <div className="form-group">
                    <label className={`form-label ${focused.phone || (!focused.phone && formData.phone != "") ? "label-blured" : ""}`}>საკონტაქტო ნომერი</label>
                    <input 
                        className="form-control" 
                        value={formData.phone}
                        onBlur={() => setFocused((prev) => ({...prev, phone: false}))}
                        onFocus={() => setFocused((prev) => ({...prev, phone: true}))}
                        onChange={(e) => setFormData((prev) => ({...prev, phone: e.target.value}))}
                    />
                </div>
                <div className="form-group">
                    <label className={`form-label ${focused.birthDate || (!focused.birthDate && formData.birthDate != "") ? "label-blured" : ""}`}>დაბადების წელი</label>
                    <input 
                        className="form-control" 
                        value={formData.birthDate}
                        onBlur={() => setFocused((prev) => ({...prev, birthDate: false}))}
                        onFocus={() => setFocused((prev) => ({...prev, birthDate: true}))}
                        onChange={(e) => setFormData((prev) => ({...prev, birthDate: e.target.value}))}
                    />
                </div>
                <div className="form-group">
                    <label className={`form-label ${focused.address || (!focused.address && formData.address != "") ? "label-blured" : ""}`}>მისამართი</label>
                    <input 
                        className="form-control" 
                        value={formData.address}
                        onBlur={() => setFocused((prev) => ({...prev, address: false}))}
                        onFocus={() => setFocused((prev) => ({...prev, address: true}))}
                        onChange={(e) => setFormData((prev) => ({...prev, address: e.target.value}))}
                    />
                </div>
                <button className="save-personal-details">შენახვა</button>
            </div>}
            {currentTab == 2 && <div className="main-page-contact-form persinal-info-form" style={{flexDirection: "column"}}>
                <div className="form-group" style={{marginBottom: "16px"}}>
                    <label className={`form-label ${focused.name || (!focused.name && passwordForm.oldPassword != "") ? "label-blured" : ""}`}>ამჟამინდელი პაროლი</label>
                    <input 
                        value={passwordForm.oldPassword}
                        className="form-control" 
                        onBlur={() => setFocused((prev) => ({...prev, name: false}))}
                        onFocus={() => setFocused((prev) => ({...prev, name: true}))}
                        onChange={(e) => setPasswordForm((prev) => ({...prev, oldPassword: e.target.value}))}
                    />
                </div>
                <div className="form-group" style={{marginBottom: "16px"}}>
                    <label className={`form-label ${focused.name || (!focused.name && formData.name != "") ? "label-blured" : ""}`}>ახალი პაროლი</label>
                    <input 
                        value={passwordForm.newPassword}
                        className="form-control" 
                        onBlur={() => setFocused((prev) => ({...prev, name: false}))}
                        onFocus={() => setFocused((prev) => ({...prev, name: true}))}
                        onChange={(e) => setPasswordForm((prev) => ({...prev, newPassword: e.target.value}))}
                    />
                </div>
                <div className="form-group" style={{marginBottom: "16px"}}>
                    <label className={`form-label ${focused.name || (!focused.name && formData.name != "") ? "label-blured" : ""}`}>გაიმეორეთ პაროლი</label>
                    <input 
                        value={passwordForm.repeatPassword}
                        className="form-control" 
                        onBlur={() => setFocused((prev) => ({...prev, name: false}))}
                        onFocus={() => setFocused((prev) => ({...prev, name: true}))}
                        onChange={(e) => setPasswordForm((prev) => ({...prev, repeatPassword: e.target.value}))}
                    />
                </div>
                <button className="change-password-button">პაროლის შეცვლა</button>
            </div>}
        </div>
    )
}

export default PersonalDetails