import { useEffect, useState } from "react";
import axios from "axios";

const TopUpBalance = ({setHeaderTitle, setNoBackground}) => {
    const [selectedCurrency, setSelectedCurrency] = useState("usd");
    const [data, setData] = useState({});
    const [focused, setFocused] = useState({amount: false});
    const [formData, setFormData] = useState({amount: "0"});
    const [bankCards, setBankCards] = useState([]);
    
    useEffect(() => {
        setHeaderTitle("ბალანსის შევსება");
        setNoBackground(false);

        axios('/api/user')
            .then((res) => {
                setData(res.data.data);
                setBankCards(typeof res.data.data.cart == "string" ? JSON.parse(res.data.data.cart) : res.data.data.cart);
            })
            .catch((err) => {

            })
    }, [])

    const handlers = {
        changeCurrency (){
            setSelectedCurrency(selectedCurrency == "usd" ? "gel" : "usd")
        }
    }

    return(
        <div className="personal-details-page-container">
            <div className="top-up-balance-header">
                <button onClick={handlers.changeCurrency} className="change-active-currency">{selectedCurrency == "usd" ? "₾" : "$"}</button>
                <div className="current-balance-amount">
                    <p className="current-balance-amount-title">ხელმისაწვდომი თანხა</p>
                    <p className="current-balance-amount-value">{data.balance} ₾</p>
                </div>
                <div className="pay-amount-block">
                    <div className="form-group">
                        <label className={`form-label ${focused.amount || (!focused.amount && formData.amount != "") ? "label-blured" : ""}`}>თანხა</label>
                        <input 
                            className="form-control" 
                            value={formData.amount}
                            onBlur={() => setFocused((prev) => ({...prev, amount: false}))}
                            onFocus={() => setFocused((prev) => ({...prev, amount: true}))}
                            onChange={(e) => setFormData((prev) => ({...prev, amount: e.target.value}))}
                        />
                    </div>
                </div>
                <button className="top-up-balance-button">ბალანსის შევსება</button>
            </div>
            <div className="top-up-page-divider"></div>
            <h3 className="cart-list-title">ბარათები</h3>
            <div className="bank-cards-list">
                {
                    bankCards.map((card) => {
                        return(
                            <div className={`bank-card-box ${card.bank == "tbc" ? "tbc-card" : "bog-card"}`}>
                                <p className="bank-name">{card.bank == "tbc" ? "თიბისი ბანკი" : "საქართველოს ბანკი"}</p>
                                <div className="bank-card-footer">
                                    <p>•••• 4237</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                        <path d="M16.5405 1.71143H9.52881V14.2888H16.5405V1.71143Z" fill="#FF5A00"/>
                                        <path d="M9.99556 8C9.99556 5.44466 11.1987 3.1767 13.045 1.71133C11.6862 0.64466 9.97222 0 8.10262 0C3.67365 0 0.0900269 3.57799 0.0900269 8C0.0900269 12.422 3.67365 16 8.10262 16C9.97222 16 11.6862 15.3553 13.045 14.2887C11.1961 12.844 9.99556 10.5553 9.99556 8Z" fill="#EB001B"/>
                                        <path d="M26.0001 8C26.0001 12.422 22.4165 16 17.9875 16C16.1179 16 14.4039 15.3553 13.0451 14.2887C14.9147 12.8207 16.0946 10.5553 16.0946 8C16.0946 5.44466 14.8914 3.1767 13.0451 1.71133C14.4013 0.64466 16.1153 0 17.9849 0C22.4165 0 26.0001 3.60129 26.0001 8Z" fill="#F79E1B"/>
                                    </svg>
                                </div>
                            </div>
                        )
                    })
                }
                <button className="add-new-card-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M17.4167 11.9149H11.9167V17.4149H10.0834V11.9149H4.58337V10.0815H10.0834V4.58154H11.9167V10.0815H17.4167V11.9149Z" fill="black"/>
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default TopUpBalance