import axios from "axios";
import { useEffect, useState } from "react"

const WonVehicles = ({setHeaderTitle, setNoBackground}) => {
    const [options, setOptions] = useState({status: "won", sort: "date"});
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(20);
    const [data, setData] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [loading, setLoading] = useState(false);

    function formatNumberWithCommas(number) {
        return number.toLocaleString('en-US');
    }

    function formatDate(d) {
        const date = new Date(d);
        return `${date.getHours() < 10 ? "0" : ""}${date.getHours()}:${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}`;
    }

    useEffect(() => {
        setLoading(true);
        axios(`/api/orders/placed-bids?limit=${limit}&page=${page}&keyword=${keyword}&status=${options.status}`)
            .then((res) => {
                setLoading(false);
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((err) => {
                setLoading(false);
            })
        setHeaderTitle("აუქციონი");
        setNoBackground(false);
    }, [options]);

    return(
        <div className={`personal-details-page-container won-vehicles-page ${(data.length == 0 && !loading) && "won-vehicle-min-height"}`}>
            <div className="won-vehicles-search-box">
                <button className="search-won-vehicle-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M14.5 13.793L10.724 10.017C11.6314 8.92765 12.0839 7.53042 11.9873 6.11595C11.8908 4.70148 11.2526 3.37869 10.2056 2.42275C9.15861 1.46682 7.78337 0.951334 6.36598 0.98354C4.94858 1.01575 3.59817 1.59316 2.59567 2.59567C1.59316 3.59817 1.01575 4.94858 0.98354 6.36598C0.951334 7.78337 1.46682 9.15861 2.42275 10.2056C3.37869 11.2526 4.70148 11.8908 6.11595 11.9873C7.53042 12.0839 8.92765 11.6314 10.017 10.724L13.793 14.5L14.5 13.793ZM2 6.5C2 5.60998 2.26392 4.73995 2.75838 3.99993C3.25285 3.25991 3.95565 2.68313 4.77792 2.34254C5.60019 2.00194 6.50499 1.91283 7.3779 2.08646C8.25082 2.2601 9.05264 2.68868 9.68198 3.31802C10.3113 3.94735 10.7399 4.74918 10.9135 5.62209C11.0872 6.495 10.998 7.3998 10.6575 8.22207C10.3169 9.04434 9.74008 9.74714 9.00006 10.2416C8.26004 10.7361 7.39001 11 6.5 11C5.30693 10.9987 4.16311 10.5241 3.31948 9.68052C2.47585 8.83689 2.00132 7.69306 2 6.5Z" fill="white"/>
                    </svg>
                </button>
                <input 
                    type="text"
                    placeholder="ძებნა"
                    className="search-won-vehicle-input"
                />
            </div>
            <div className="won-vehicles-filter">
                <div className="won-vehicles-filter-buttons">
                    <button 
                        onClick={() => setOptions((prev) => ({...prev, status: "won"}))} 
                        className={`won-vehicle-filter-button ${options.status == "won" ? "active" : ""}`}
                    >
                        მოგებული
                    </button>
                    <button 
                        onClick={() => setOptions((prev) => ({...prev, status: "lose"}))}
                        className={`won-vehicle-filter-button ${options.status == "lose" ? "active" : ""}`}
                    >
                        წაგებული
                    </button>
                    <button 
                        onClick={() => setOptions((prev) => ({...prev, status: "active"}))} 
                        className={`won-vehicle-filter-button ${options.status == "active" ? "active" : ""}`}
                    >
                        მიმდინარე
                    </button>
                </div>
                <button className="sort-vehicles-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path d="M18.2084 9.99999H7.91252M4.27835 9.99999H2.79169M4.27835 9.99999C4.27835 9.51818 4.46975 9.0561 4.81044 8.71541C5.15113 8.37472 5.61321 8.18332 6.09502 8.18332C6.57683 8.18332 7.03891 8.37472 7.3796 8.71541C7.72029 9.0561 7.91169 9.51818 7.91169 9.99999C7.91169 10.4818 7.72029 10.9439 7.3796 11.2846C7.03891 11.6253 6.57683 11.8167 6.09502 11.8167C5.61321 11.8167 5.15113 11.6253 4.81044 11.2846C4.46975 10.9439 4.27835 10.4818 4.27835 9.99999ZM18.2084 15.5058H13.4184M13.4184 15.5058C13.4184 15.9877 13.2265 16.4503 12.8857 16.7911C12.545 17.1319 12.0828 17.3233 11.6009 17.3233C11.119 17.3233 10.657 17.1311 10.3163 16.7904C9.97559 16.4497 9.78419 15.9876 9.78419 15.5058M13.4184 15.5058C13.4184 15.0239 13.2265 14.5621 12.8857 14.2214C12.545 13.8806 12.0828 13.6892 11.6009 13.6892C11.119 13.6892 10.657 13.8806 10.3163 14.2212C9.97559 14.5619 9.78419 15.024 9.78419 15.5058M9.78419 15.5058H2.79169M18.2084 4.49416H15.6209M11.9867 4.49416H2.79169M11.9867 4.49416C11.9867 4.01235 12.1781 3.55027 12.5188 3.20958C12.8595 2.86889 13.3215 2.67749 13.8034 2.67749C14.0419 2.67749 14.2782 2.72448 14.4986 2.81578C14.719 2.90707 14.9192 3.04089 15.0879 3.20958C15.2566 3.37827 15.3904 3.57854 15.4817 3.79895C15.573 4.01936 15.62 4.25559 15.62 4.49416C15.62 4.73272 15.573 4.96896 15.4817 5.18937C15.3904 5.40977 15.2566 5.61004 15.0879 5.77873C14.9192 5.94743 14.719 6.08124 14.4986 6.17254C14.2782 6.26383 14.0419 6.31082 13.8034 6.31082C13.3215 6.31082 12.8595 6.11943 12.5188 5.77873C12.1781 5.43804 11.9867 4.97597 11.9867 4.49416Z" stroke="#5A7EFF" strokeOpacity="0.9" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
                    </svg>
                    <span>სორტირება</span>
                </button>
            </div>
            {
                loading && <div style={{display: "flex", justifyContent: "center"}}><div style={{marginTop: "40px"}} className="content-loader"></div></div>
            }
            {
                !loading && data.length > 0 ? (
                    <table className="won-vehicles-table">
                        <thead className="won-vehicles-table-header">
                            <tr>
                                <th className="vehicle-img-row">{total == 0 ? "0" : ((page - 1) * 20) + 1}{total > 0 && "-"}{total > 0 && total < page * 20 ? total : total == 0 ? "" : page * 20} განცხადება {total}-დან</th>
                                <th>მარკა</th>
                                <th>მოდელი</th>
                                <th>წელი</th>
                                <th>ადგილმდებარეობა</th>
                                <th>თარიღი</th>
                                <th>ბიდი/ლოტი</th>
                                <th>სრული თანხა</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr ><td className="won-vehicles-table-divider" colSpan={8}></td></tr>
                            {
                                data.map((car) => {
                                    return(
                                        <tr key={car.lot}>
                                            <td>
                                                <div className="vehicle-list-table-image" style={{backgroundImage: `url(${car.image})`}}></div>
                                            </td>
                                            <td>
                                                <p className="td-first-row pt-28">{car.mark}</p>
                                                <p className="td-second-row">{car.body_style}</p>
                                            </td>
                                            <td>
                                                <p className="td-first-row pt-28">{car.model}</p>
                                                <p className="td-second-row"></p>
                                            </td>
                                            <td>
                                                <p className="td-first-row pt-28">{car.year}</p>
                                                <p className="td-second-row"></p>
                                            </td>
                                            <td>
                                                <p className="td-first-row pt-28">{car.country}</p>
                                                <p className="td-second-row">{car.location}</p>
                                            </td>
                                            <td>
                                                <p className="td-first-row pt-28">{car.auction_date.slice(0,10)}</p>
                                                <p className="td-second-row">{formatDate(car.auction_date)}</p>
                                            </td>
                                            <td>
                                                <p className="td-first-row pt-28">${formatNumberWithCommas(parseInt(car.amount))}</p>
                                                <p className="td-second-row">#{car.lot}</p>
                                            </td>
                                            <td>
                                                <p className="td-first-row pt-28">${formatNumberWithCommas(parseInt(car.amount) + parseInt(car.auction_fee))}</p>
                                                <p className="td-second-row"></p>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                ) : (
                    !loading && <div className="no-won-item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="168" height="168" viewBox="0 0 168 168" fill="none">
                        <g clipPath="url(#clip0_899_4649)">
                            <path d="M106.653 37.7826H61.3847C60.3531 37.7839 59.3641 38.2043 58.6347 38.9516C57.9052 39.6989 57.4949 40.7121 57.4936 41.7689V144.84L56.9748 145.002L45.8697 148.486C45.3434 148.65 44.7749 148.594 44.2891 148.33C43.8032 148.065 43.4397 147.614 43.2783 147.075L10.2456 36.5363C10.0848 35.9971 10.1396 35.4146 10.3979 34.9168C10.6562 34.419 11.0969 34.0466 11.623 33.8814L28.736 28.5133L78.3472 12.9563L95.4601 7.58816C95.7205 7.50605 95.9942 7.47736 96.2655 7.50372C96.5367 7.53009 96.8002 7.611 97.041 7.74183C97.2817 7.87265 97.4948 8.05082 97.6683 8.26613C97.8417 8.48145 97.9719 8.72969 98.0516 8.99664L106.495 37.2512L106.653 37.7826Z" fill="#F2F2F2"/>
                            <path d="M116.532 37.2514L106.355 3.19823C106.186 2.63085 105.909 2.10319 105.541 1.64543C105.172 1.18767 104.719 0.808777 104.208 0.530393C103.696 0.252009 103.137 0.0795917 102.56 0.0230007C101.984 -0.0335904 101.402 0.0267535 100.848 0.200578L76.7882 7.74521L27.1797 23.3049L3.11992 30.8521C2.00223 31.2037 1.06637 31.9953 0.517765 33.0531C-0.0308402 34.1108 -0.147372 35.3483 0.193754 36.494L34.9748 152.874C35.2519 153.799 35.811 154.608 36.5702 155.183C37.3293 155.759 38.2485 156.07 39.1928 156.071C39.6298 156.071 40.0644 156.004 40.4819 155.871L56.9749 150.7L57.4937 150.535V149.98L56.9749 150.142L40.3289 155.364C39.3424 155.672 38.2768 155.567 37.366 155.071C36.4551 154.575 35.7734 153.73 35.4703 152.72L0.691918 36.3372C0.541746 35.8368 0.489407 35.3109 0.537896 34.7898C0.586386 34.2686 0.734747 33.7623 0.974494 33.3C1.21424 32.8376 1.54066 32.4282 1.93506 32.0953C2.32946 31.7624 2.78409 31.5124 3.27292 31.3597L27.3327 23.8124L76.9414 8.25546L101.001 0.708165C101.372 0.592245 101.757 0.533139 102.145 0.532769C102.977 0.534683 103.786 0.809456 104.455 1.31684C105.123 1.82423 105.616 2.53757 105.86 3.35237L115.99 37.2514L116.15 37.7829H116.69L116.532 37.2514Z" fill="#3F3D56"/>
                            <path d="M31.8241 33.9683C31.3241 33.968 30.8374 33.8035 30.4354 33.499C30.0334 33.1945 29.7372 32.766 29.5903 32.2764L26.2491 21.0958C26.1593 20.7955 26.1281 20.48 26.1574 20.1673C26.1866 19.8547 26.2757 19.551 26.4196 19.2736C26.5634 18.9962 26.7592 18.7506 26.9957 18.5507C27.2322 18.3508 27.5048 18.2006 27.798 18.1087L73.4375 3.79446C74.0295 3.60937 74.6691 3.6725 75.2157 3.96997C75.7624 4.26745 76.1716 4.77498 76.3534 5.38117L79.6946 16.5619C79.8751 17.1684 79.8135 17.8235 79.5231 18.3836C79.2327 18.9436 78.7374 19.3627 78.1458 19.5491L32.5062 33.8634C32.2852 33.9328 32.0553 33.9682 31.8241 33.9683Z" fill="#F9CA81"/>
                            <path d="M49.3268 11.9467C52.1921 11.9467 54.5149 9.5671 54.5149 6.63171C54.5149 3.69632 52.1921 1.31671 49.3268 1.31671C46.4615 1.31671 44.1387 3.69632 44.1387 6.63171C44.1387 9.5671 46.4615 11.9467 49.3268 11.9467Z" fill="#F9CA81"/>
                            <path d="M49.3268 9.99732C51.1412 9.99732 52.612 8.49048 52.612 6.6317C52.612 4.77292 51.1412 3.26608 49.3268 3.26608C47.5124 3.26608 46.0415 4.77292 46.0415 6.6317C46.0415 8.49048 47.5124 9.99732 49.3268 9.99732Z" fill="white"/>
                            <path d="M156.327 154.713H68.6481C68.0635 154.712 67.503 154.474 67.0897 154.05C66.6763 153.627 66.4438 153.053 66.4431 152.454V44.825C66.4438 44.2261 66.6763 43.652 67.0896 43.2285C67.503 42.805 68.0635 42.5668 68.6481 42.5662H156.327C156.911 42.5669 157.472 42.8051 157.885 43.2285C158.299 43.652 158.531 44.2262 158.532 44.825V152.454C158.531 153.053 158.299 153.627 157.885 154.05C157.472 154.474 156.911 154.712 156.327 154.713Z" fill="#E6E6E6"/>
                            <path d="M115.989 37.2512H61.3845C60.2154 37.2529 59.0947 37.7294 58.2681 38.5763C57.4414 39.4231 56.9763 40.5712 56.9746 41.7689V150.142L57.4934 149.98V41.7689C57.4947 40.7121 57.905 39.6989 58.6345 38.9516C59.3639 38.2043 60.3529 37.7839 61.3845 37.7827H116.15L115.989 37.2512ZM163.59 37.2512H61.3845C60.2154 37.2529 59.0947 37.7294 58.2681 38.5763C57.4414 39.4231 56.9763 40.5712 56.9746 41.7689V163.482C56.9763 164.68 57.4414 165.828 58.2681 166.675C59.0947 167.522 60.2154 167.998 61.3845 168H163.59C164.759 167.998 165.88 167.522 166.706 166.675C167.533 165.828 167.998 164.68 168 163.482V41.7689C167.998 40.5712 167.533 39.4231 166.706 38.5763C165.88 37.7294 164.759 37.2529 163.59 37.2512ZM167.481 163.482C167.48 164.539 167.069 165.552 166.34 166.3C165.611 167.047 164.622 167.467 163.59 167.468H61.3845C60.3529 167.467 59.3639 167.047 58.6345 166.3C57.905 165.552 57.4947 164.539 57.4934 163.482V41.7689C57.4947 40.7121 57.905 39.6989 58.6345 38.9516C59.3639 38.2043 60.3529 37.7839 61.3845 37.7827H163.59C164.622 37.7839 165.611 38.2043 166.34 38.9516C167.069 39.6989 167.48 40.7121 167.481 41.7689V163.482Z" fill="#3F3D56"/>
                            <path d="M136.353 48.9441H88.6222C88.0033 48.9434 87.4098 48.6912 86.9722 48.2428C86.5345 47.7944 86.2883 47.1865 86.2876 46.5524V34.8594C86.2883 34.2253 86.5345 33.6174 86.9722 33.169C87.4099 32.7206 88.0033 32.4684 88.6222 32.4677H136.353C136.972 32.4684 137.565 32.7206 138.003 33.169C138.44 33.6174 138.687 34.2253 138.687 34.8594V46.5524C138.687 47.1865 138.441 47.7944 138.003 48.2428C137.565 48.6912 136.972 48.9434 136.353 48.9441Z" fill="#F9CA81"/>
                            <path d="M112.487 33.2649C115.353 33.2649 117.676 30.8853 117.676 27.9499C117.676 25.0145 115.353 22.6349 112.487 22.6349C109.622 22.6349 107.299 25.0145 107.299 27.9499C107.299 30.8853 109.622 33.2649 112.487 33.2649Z" fill="#F9CA81"/>
                            <path d="M112.487 31.1872C114.233 31.1872 115.647 29.7378 115.647 27.9499C115.647 26.162 114.233 24.7126 112.487 24.7126C110.742 24.7126 109.327 26.162 109.327 27.9499C109.327 29.7378 110.742 31.1872 112.487 31.1872Z" fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_899_4649">
                            <rect width="168" height="168" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <p>განცხადება ვერ მოიძებნა</p>
                </div>
                )
            }
        </div>
    )
}

export default WonVehicles