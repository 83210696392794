import { useEffect, useState } from "react"
import AutoAuctionBanner from "../components/MainPage/AutoAuctionBanner/AutoAuctionBanner"
import Banner from "../components/MainPage/Banner"
import MainPageContact from "../components/MainPage/MainPageContact/MainPageContact"
import NewsCard from "../components/MainPage/NewsCard/NewsCard"
import PopularBrandsList from "../components/MainPage/PopularBrandsList/PopularBrandsList"
import PopularVehicles from "../components/MainPage/PopularVehicles/PopularVehicles"
import TransportationCalculator from "../components/MainPage/TransportationCalculator/TransportationCalculator"
import VehicleTypeSelector from "../components/MainPage/VehicleTypeSelector/VehicleTypeSelector"
import WhyUs from "../components/MainPage/WhyUs/WhyUs"
import axios from "axios"

const MainPage = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: for smooth scrolling
        });
        axios.get('/api/latest/blogs?limit=3')
            .then((res) => {
                setBlogs(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return(
        <div className="main-page-container">
            <Banner />
            <img src="/static/banner-car.png" className="banner-car-image" alt="banner-vehicle"/>
            <div className="main-page-content">
                <VehicleTypeSelector />
                <WhyUs />
                <AutoAuctionBanner />
                <PopularVehicles />
                <PopularBrandsList />
                <TransportationCalculator />
                <div className="news-card-slider">
                    <h3 className="section-title">საინტერესო სიახლეები</h3>
                    <div className="main-page-news-card-list">
                        {
                            blogs.length > 0 && (
                                blogs.map((b, index) => {
                                    return <NewsCard key={index} data={b}/>
                                })
                            )
                        }
                        {
                            blogs.length == 0 && <h3>სიახლეები ვერ მოიძებნა</h3>
                        }
                    </div>
                </div>
                <div className="main-page-contacts">
                    <h3 className="section-title">კონტაქტი</h3>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2104.2662599072855!2d44.768262881593394!3d41.76620437634921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40446dfe78aed8a9%3A0xe14a15e2ab5249d4!2zQ2Fyc3BhY2Ug4oCiIOGDpeGDkOGDoOGDoeGDpOGDlOGDmOGDoeGDmA!5e0!3m2!1sen!2sge!4v1727348755975!5m2!1sen!2sge" width="100%" height="750" style={{border: 0, borderRadius: "20px", marginBottom: "84px"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <MainPageContact />
            </div>
        </div>
    )
}

export default MainPage