import { useEffect, useState } from "react"
import "./CalculatorDropdown.css"
import OutsideClickHandler from "react-outside-click-handler";

const CalculatorDropdown = ({label, defaultValue, listValue, setter, selected, shownDropdown, setShownDropdown, withInput, inputPlaceHolder}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(listValue);
    }, [listValue])
    return(
        <div className="calculator-dropdown-container">
            <div className="calculator-dropdown-select-box" onClick={() => setShownDropdown()}>
                <p className="calculator-dropdown-label">{label}</p>
                <div className="calculator-dropdown-selected-value">
                    <p className="calculator-dropdown-active-value">{selected == "" ? defaultValue : selected}</p>
                    <img src="/static/icons/down-icon.svg" alt="down"/>
                </div>
            </div>
            {shownDropdown && <OutsideClickHandler onOutsideClick={() => setShownDropdown(false)}>
                <div className="calculator-dropdown-list-box">
                    {withInput && (
                        <input type="text" className="calculator-dropdown-search" placeholder={inputPlaceHolder} onChange={(e) => {
                            const filtered = listValue.filter((v) => v.name.toLowerCase().includes(e.target.value.toLowerCase()));
                            setData(filtered);
                        }} />
                    )}
                    {listValue.length == 0 || data.length == 0 ? <p className="records-not-found">ჩანაწერი ვერ მოიძებნა</p> : (
                        <>
                            {withInput ? data.map((item, i) => {
                                return <p key={i} onClick={() => {
                                    setter(item.name);
                                    setShownDropdown();
                                }} className="calculator-dropdown-item">{item.name}</p>
                            }) : listValue.map((item, i) => {
                                return <p onClick={() => {
                                    setter(item.name);
                                    setShownDropdown();
                                }} key={i} className="calculator-dropdown-item">{item.name}</p>
                            })}
                        </>
                    )}
                </div>    
            </OutsideClickHandler>}
        </div>
    )
}

export default CalculatorDropdown