import axios from "axios"
import { useEffect, useState, useReducer } from "react"
import VehicleList from "../components/Catalogue/VehicleList/VehicleList";
import Filter from "../components/Catalogue/Filter/Filter";
import Pagination from '@mui/material/Pagination';
import { filterInitialState, filterReducer } from "../reducers/filterReducer";

const Catalogue = ({setSelectedLot}) => {
    const [carsList, setCarsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagerConfig, setPagerConfig] = useState({page: 1, limit: 20, sort_by: ""});
    const [total, setTotal] = useState("0");
    const [errors, setErrors] = useState({carLoading: false});
    const [useFilter, setUseFilter] = useState(false);
    const [filter, dispatchFilter] = useReducer(filterReducer, filterInitialState)
    const loaders = {
        async cars(){
            try{
                setCarsList([]);
                setLoading(true);
                const response = await axios.post('/api/auction/copart/vehicle-finder', {...filter, ...pagerConfig});
                setLoading(false);
                setCarsList([...response.data.data]);
                setTotal(response.data.total);
            }catch(err) {
                setErrors((prev) => ({...prev, carLoading: true}));
            }
        }
    }

    useEffect(() => {
        loaders.cars();
    }, [pagerConfig]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: for smooth scrolling
        });
    }, [pagerConfig]);

    const submitSearch = () => {
        setPagerConfig({page: 1, limit: 20, sort_by: ""});
        if(pagerConfig.page == 1){
            loaders.cars()
        }
    }

    const submitSearchKeyword = async (token) => {
        try{
            const response = await axios.post(`/api/auction/copart/copart/${filter.keyword}`, {token: token});
            setLoading(false);
            const lot = response.data.data;
            window.location.href = `/vehicle/${lot}/USA`;
        }catch(err) {
            setErrors((prev) => ({...prev, carLoading: true}));
        }
    }
    
    return(
        <div className="catalogue-page-container">
            <span className="page-title">კატალოგი <img src="/static/icons/right-arrow.svg" alt="right-arrow"/></span>
            <Filter
                submitSearch={submitSearch}
                setLoading={setLoading}
                setCarsList={setCarsList}
                setTotal={setTotal}
                setErrors={setErrors}
                setUseFilter={setUseFilter}
                filterPage={pagerConfig.page}
                useFilter={useFilter}
                formData={filter}
                setFormData={dispatchFilter}
                submitSearchKeyword={submitSearchKeyword}
            />
            <VehicleList 
                cars={carsList} 
                setCats={setCarsList} 
                setPagerConfig={setPagerConfig}
                loading={loading}
                setLoading={setLoading}
                pagerConfig={pagerConfig}
                setSelectedLot={setSelectedLot}
            />
            <div className="catalogue-pagination-container">
                <Pagination count={parseInt(total / pagerConfig.limit)} page={pagerConfig.page} onChange={(event, val) => {
                    setPagerConfig((prev) => ({...prev, page: val}));
                }} />
            </div>
            <p className="showing-results-count">Showing Results: {pagerConfig.page == 1 ? `1-${pagerConfig.limit}` : `${((pagerConfig.page - 1) * pagerConfig.limit) + 1}-${pagerConfig.limit * pagerConfig.page}`} of {total}</p>
        </div>
    )
}

export default Catalogue